import { Circle, GoogleMap, Marker } from '@react-google-maps/api';
import React, { useRef, useState } from 'react';
import { IMarker } from 'types/IMarker';
import { IPosition } from 'types/IPosition';
import { GoogleMapConfig } from 'configurations/GoogleMapConfig';
import { useMap } from 'hooks/useMap';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import 'components/basic/Map/Map.scss';
import { MoroccanMaps } from 'configurations/MoroccanMaps';

type TMapType = 'picker' | 'show-only';

interface IMapProps {
  isFitBounds?: boolean;
  type: TMapType;
  center: IPosition;
  setCenter?: (position: IPosition) => void;
  markers: IMarker[];
}

export function Map({ isFitBounds, type, center, setCenter, markers }: IMapProps): React.ReactElement {
  const { fitBounds, zoom } = useMap();
  const [dragging, setDragging] = useState<boolean>(false);
  const mapRef = useRef<google.maps.Map>();

  const handleDragStart = () => {
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);

    if (!mapRef.current) {
      return;
    }

    const lat = mapRef.current.getCenter()?.lat();
    const lng = mapRef.current.getCenter()?.lng();

    if (!lat || !lng || !setCenter) {
      return;
    }

    setCenter({ lat, lng });
  };
    const geoJsonData = {
        type: "FeatureCollection",
        features: MoroccanMaps.feature
    };

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
      map.data.addGeoJson(geoJsonData);
      map.data.setStyle({
          strokeColor: '#bbbbbb',
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0,
      });

  };

  const handleFitBounds = () => {
    if (isFitBounds) {
      fitBounds(mapRef.current, markers);
      zoom(mapRef.current, -1);
    }
  };

  useUpdateEffect(
    handleFitBounds,
    [mapRef.current, markers]
  );

  return (
    <GoogleMap
      mapContainerStyle={GoogleMapConfig.mapContainerStyle}
      options={GoogleMapConfig.options}
      zoom={GoogleMapConfig.defaultZoom}
      center={center}
      onLoad={handleLoad}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}

    >
      {type === 'picker' && dragging && <div id='centerMarker' />}
      {markers.map((marker) => {
        return (
          marker.show && (
            <React.Fragment key={marker.id}>
              <Marker
                position={marker.position}
                icon={{ url: marker.icon, scaledSize: new google.maps.Size(80, 80) }}
              />
              {marker.circle.show && (
                <Circle
                  options={{ strokeColor: marker.circle.color, fillColor: marker.circle.color, fillOpacity: 0.1 }}
                  center={marker.position}
                  radius={marker.circle.radiusInMeters}
                />
              )}
            </React.Fragment>
          )
        );
      })}
    </GoogleMap>
  );
}
