export const GoogleMapConfig = {
  defaultZoom: 12,
  mapContainerStyle: {
    width: '100%',
    height: '100%'
  },
  options: {
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    gestureHandling: 'greedy',
    maxZoom: 18,
    styles : [
          {
              featureType: "administrative.country",
              stylers: [
                  {
                      visibility: "off"
                  }
              ]
          }
      ]
  }
};
